import { Table, Button, Space, Modal, Form, Select, Input, DatePicker } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import service from '../config/service';
import fileDownload from 'js-file-download';
import convertor from 'json-2-csv';
import FormEmployee from '../pages/employees/update/form';
import { useSelector } from 'react-redux';

const { Option } = Select;

const AdminEmployeesComponent = ({ values, submitted, setSubmitted, secret, section, setCompletedEntries }) => {
    //alert('re-rendered');
    const userReducer = useSelector(state => state.userReducer);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEmployeeModalVisible, setIsEmployeeModalVisible] = useState(false);
    const [currentDeleteUser, setCurrentDeleteUser] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [reason, setReason] = useState('');
    const [form] = Form.useForm();

    let updateForm = "updateForm";

    if (secret)
        updateForm = "updateSecretForm";

    useEffect(() => {
        if (currentDeleteUser && currentDeleteUser.status) {
            form.setFieldsValue({ reason: currentDeleteUser.status?.reason, comments: currentDeleteUser.status?.comments, date: moment(currentDeleteUser.status?.date) });
        }
    }, [currentDeleteUser]);

    const { data, isLoading, isError, isFetching } = useQuery(['data'], async () => {
        const res = await service.getRestClient().post('/employees/get', { ...values });
        setSubmitted(false);
        return res.data.data;
    }, { enabled: submitted, refetchOnWindowFocus: false })

    // used incase of resignation
    const deleteMutation = useMutation(async (value) => {
        await service.getRestClient().post('/employees/deleteEmployee', value)
    }, {
        onSuccess: () => queryClient.refetchQueries('data'),
        onError: (e) => alert('failed to delete entry: ' + e.message)
    })

    // to be removed from DB. by admin only
    const removeMutation = useMutation(async value => {
        await service.getRestClient().post('/employees/removeEmployee', {_id: value})
    }, {
        onSuccess: () => queryClient.refetchQueries('data'),
        onError: (e) => alert('failed ro remove this employee: ' + e.message)
    })

    const queryClient = useQueryClient();

    const onDownload = async () => {
        const saveData = data.map(item => {
            delete item._id;
            delete item.__v;

            return item;
        })
        const csv = await convertor.json2csvAsync(saveData);
        fileDownload(csv, `${values.occupation || 'data'}.csv`);
    }

    const onDelete = async (value) => {
        try {
            console.log(value);
            deleteMutation.mutate(value);
        }
        catch (e) {
            alert(`Error occured while deleting: ${e.message}`);
        }
    }


    if (isLoading || isFetching)
        return (<div>Loading data ...</div>)

    if (isError)
        return (<div>Error Loading data. Please try again</div>)

    if (!data) {
        return (<div>No data</div>)
    }

    const backgroundColorRender = (text, record, index) => {
        if (section !== 'doctor') {
            return <div>{index}</div>;
        }
        
        if (
                (!Object.keys(record).includes('status') || Object.keys(record.status).length < 1) && // emp exist
                moment(record.updatedAt).isBefore(moment().subtract(2, 'months'))) {
            return {
                props: {
                    style: {
                        background: '#FF3131',
                        color: 'white',
                    },
                },
                children: <div>{index}</div>,
            }
        }
        else {
            return <div>{index}</div>;
        }
    }

    const columns = [
        {
            title: '#',
            key: 'index',
            align: 'center',
            render: (text, record, index) => backgroundColorRender(index, record, index+1),
        },
        {
            title: 'File No.',
            dataIndex: 'fileNo',
            key: 'fileNo',
            render: (text, record) => backgroundColorRender(text, record, text),
        },
        {
            title: 'Name',
            dataIndex: 'nameEn',
            key: 'nameEn',
            render: (text, record) => {
                //let output = <Link style={{color: record.transfer.unconfirmed ? 'red': ''}} to={`/${updateForm}/${record.fileNo}/${record.civilId}/${record._id}`}  target="_blank" rel="noopener noreferrer">{text}</Link>;

                let name = <a style={{ textDecoration: 'underline' }} onClick={() => {
                    queryClient.removeQueries(record.fileNo);
                    setCurrentUser(record);
                    setIsEmployeeModalVisible(true);
                }}>{text || record.nameAr}</a>

                let output = name;

                if (record.status && Object.keys(record.status).length > 0) 
                    output = <strike>{name}</strike>

                return backgroundColorRender(text, record, output);
            }
        },
        {
            title: 'JobTitle',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            render: (text, record) => backgroundColorRender(text, record, text),
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            render: (text, record) => backgroundColorRender(text, record, text),
        },
        {
            title: 'Last Date',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, record) => backgroundColorRender(text, record, moment(text).format('DD/MM/YYYY'))
        },
        {
            title: '',
            key: 'action',
            align: 'center',
            render: (text, record) => {
                const output =  (
                    <div>
                        <Space size="middle">
                            <Button type="link" onClick={() => {
                                setIsModalVisible(true);
                                setCurrentDeleteUser(record);
                            }}>
                                delete
                            </Button>
                        </Space>

                        {userReducer.user.role === 'admin' && (
                            <Space size="middle">
                                <Button type="link"onClick={() => {
                                    if (window.confirm('Will be deleted forever, Are you sure?'))
                                        removeMutation.mutate(record._id);
                                }}>
                                    Remove
                                </Button>
                            </Space>

                        )}
                    </div>
                )

                return backgroundColorRender(text, record, output);
            }
        }
    ];

    const footer = () => {
        if (userReducer.user.role != 'user') {
            return (
                <center>
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={() => onDownload()} size="middle">Export CSV</Button>&nbsp;
                </center>
            )
        }
    }

    const onOk = async () => {
        form.validateFields().then(() => {
            setIsModalVisible(false);
            onDelete({ ...form.getFieldsValue(), employee_id: currentDeleteUser });
            form.resetFields();
            setCurrentDeleteUser(null);
        });

    }

    const onCancel = async () => {
        setCurrentDeleteUser(null);
        form.resetFields();
        setIsModalVisible(false);
    }

    const onEmployeeFormCancel = async () => {
        setCurrentUser(null);
        setIsEmployeeModalVisible(false);
    }

    const addNewEmployee = () => {
        setCurrentUser(null);
        setIsEmployeeModalVisible(true);
    }

    const onAddNewEmployee = () => {
        queryClient.refetchQueries('data');
        setIsEmployeeModalVisible(false);
    }

    console.log(data);

    // sort for deleted & not deleted
    let tableData1 = data.sort( (emp1, emp2) => {
        const emp1Exist = (!Object.keys(emp1).includes('status') || Object.keys(emp1.status).length < 1)
        const emp2Exist = (!Object.keys(emp2).includes('status') || Object.keys(emp2.status).length < 1)
        if (emp1Exist && emp2Exist)
            return 0;
        else if (emp1Exist && !emp2Exist) 
            return -1;
        else if (!emp1Exist && emp2Exist)
            return 1;
        else if (!emp1Exist && !emp2Exist)
            return 0;
    });

    // sort for HOD of center
    let tableData = tableData1.sort( (emp1, emp2) => {
        const emp1Exist = (!Object.keys(emp1).includes('status') || Object.keys(emp1.status).length < 1)
        const emp2Exist = (!Object.keys(emp2).includes('status') || Object.keys(emp2.status).length < 1)

        if (emp1Exist && emp2Exist) {
            if (emp1.hod == 'yes' && emp2.hod == 'no') {
                return -1;    
            }
            else if (emp1.hod == 'yes' && emp2.hod == 'yes') {
                return 0;
            }
            else if (emp1.hod == 'no' && emp2.hod == 'yes') {
                return 1;
            } 
        }

    })

    if (userReducer.user.role === 'user')
        tableData = data.filter(item => {
            //console.log(Object.keys(item).includes('status'));
            //console.log(Object.keys(item.status).length);
            //console.log(!Object.keys(item).includes('status') && Object.keys(item.status).length < 1);
            if (!Object.keys(item).includes('status') || Object.keys(item.status).length < 1)
                return item;
        });

    const nonCompletedEntries = data.filter(item => {
        if ((!Object.keys(item).includes('status') || Object.keys(item.status).length < 1) && // emp exist
        moment(item.updatedAt).isBefore(moment().subtract(2, 'months'))) {
            return item;
        }
    })

    setCompletedEntries(nonCompletedEntries.length > 0 ? false : true);

    return (<div>
        <Modal title="delete Employee" visible={isModalVisible} onOk={onOk} onCancel={onCancel}>
            <Form name="resign" form={form} layout="vertical">
                <Form.Item name="reason" label="Reason" rules={[{ required: true, message: "please enter a reason" }]}>
                    <Select
                        placeholder="Please select a reason"
                        optionFilterProp="children"
                        allowClear
                        onChange={(value) => setReason(value)}
                    >
                        <Option value="resign">Resign (استقالة)</Option>
                        <Option value="outside_transfer">Outside Transfer (نقل خارج المراكز الصحية)</Option>
                        <Option value="transfer">Transfer to other clinic (نقل لمركز آخر)</Option>
                        <Option value="deputated">Deputed (انتداب)</Option>
                        <Option value="death">Death (وفاة)</Option>
                        <Option value="sickleave">Long Sickleave (more than 6 months) (اجازة اكثر من 6 شهور)</Option>
                        <Option value="duplicate">Duplicate Entry (أسم مكرر)</Option>
                        <Option value="others">Others</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="comments" label="Comments" dependencies={['reason']}
                    rules={
                        [
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (getFieldValue('reason') === 'others' && !value) {
                                  return Promise.reject(new Error('Please enter comments for "Others"'));
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]
                    }
                >
                    <Input />
                </Form.Item>
                <Form.Item name="date" label="Effective Date">
                    <DatePicker />
                </Form.Item>
            </Form>
        </Modal>
        <Modal title="Employee information" visible={isEmployeeModalVisible} footer={null} onCancel={onEmployeeFormCancel}>
            {(currentUser?.fileNo) ?
                <FormEmployee fileNo={currentUser?.fileNo} civilId={currentUser?.civilId} id={currentUser?._id} onAdd={onAddNewEmployee} />
                :
                <div>
                    <Form layout="vertical" width="40%" name="login" initialValues={{ remember: true }} onFinish={(values) => setCurrentUser({fileNo: values.fileNo})}>
                        <Form.Item label="File No" name="fileNo" rules={[{ required: true, message: "please enter file number" }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Confirm File No" name="fileNo2" dependencies={['fileNo']} rules={[
                            { required: true, message: "please confirm your file number" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('fileNo') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two file numbers that you entered do not match!'));
                                },
                            })
                        ]}>
                            <Input />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Add
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            }
        </Modal>
        <Button onClick={addNewEmployee} type="primary">Add New {section}</Button><br /><br />
        <Table columns={columns} dataSource={tableData} pagination={{ pageSize: 200 }} size="middle" footer={footer} />
    </div>);
}

export default AdminEmployeesComponent;